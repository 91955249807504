<template>
  <section id="content" style="margin-bottom: 80px ">
    <v-card
      class="mx-auto py-1 mb-2"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="h1 mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Landscaping
          </v-card-title>
          <h4 class="text-center subtitle-1 font-weight-light mx-auto mb-5">
            Twinstreams Indigenous Nursery and Landscaping was established by
            the late Jonathan Cromhout in 2001. Jonathan and his team whom he
            worked very closely with has 13 years of experience and knowledge of
            working with a large variety of Indigenous plants as well as doing
            landscaping with a wide range of clients in KwaZulu-Natal. Our team
            has developed an expertise specialising in Zululand and it
            surrounds. We pride ourselves on strong customer relations, quality
            service and a professional attitude in everything we do, which gives
            us a large repeat business clientele. Our team is experienced in
            indigenous functional design and is ready to install any quantity of
            plants with full turnkey project management focusing on the
            restoration and conservation of our indigenous landscapes. We offer
            ecological design and consulting, site specific plant identification
            and a species inventory, site quality assessment, invasive species
            mapping and management, site planning for public, corporate,
            commercial and residential projects. Our team is experienced in the
            full spectrum of indigenous projects including the maintenance of
            these installations. We are security conscious, polite and uniformly
            dressed offering neat, tidy, efficient and economical service
            undertaken in a professional manner, timorously and with great care.
            “When it comes to garden design, we believe in being guided by
            nature, preferring not to use formal drawings, using an informal
            style and loose plantings to more of an architectural layout. It is
            important to remember it is a team effort between Twinstreams and
            the client to produce a successful product. With a little thought
            and preparation you can have a colourful interesting garden.” –
            Jonathan Cromhout
          </h4>
          <br />
          <h4 class="text-center subtitle-1 font-weight-light mx-auto mb-5">
            Services we offer: <br />
            ~ Consulting for garden design, specific to aspect, plant
            preferences ( shade or sun)<br />
            ~ Small garden species , effective small gardens, patio gardens,
            potted gardens, roof gardens <br />
            ~ Internal / external pot gardens ~ Regular garden design and
            installation <br />
            ~ Maintenance plans , dealing with disease , pests and fungi <br />
            ~ Fertilization programs for planters, garden beds and lawns <br />
            ~ Complete landscape installations, from start to finish with own
            staff and all materials supplied <br />
            ~ Complete costings and quotes for any landscape requirements <br />
            ~ Once off consultations
          </h4>
          <br />
          <div class="text-center">
            <v-card-text class="title">
              <i>Please view some of our recent projects below:</i>
            </v-card-text>
          </div>
        </v-container>
        <br />
        <v-card-title
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
          class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
        >
          Gold Coast Estate
        </v-card-title>

        <v-card class="mx-auto" height="60vh" width="80vw" tile>
          <v-carousel
            eager
            height="60vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              eager
              :src="item.src"
            />
          </v-carousel>
        </v-card>
        <!-- <br /><br />
        <v-card-title
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
          class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
        >
          Pebble Beach
        </v-card-title>  <br><br>
        <v-card
          class="mx-auto"
          height="60vh"
          width="80vw"
          tile
        >
          <v-carousel
            eager
            height="60vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(pebble, i) in pebbles"
              :key="i"
              eager
              :src="pebble.src"
            />
          </v-carousel>
        </v-card>

        <br><br>
        <v-card-title
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
          class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
        >
          Ocean Dune
        </v-card-title>
        <br><br>
        <v-card
          class="mx-auto"
          height="60vh"
          width="80vw"
          tile
        >
          <v-carousel
            eager
            height="60vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(dune, i) in dunes"
              :key="i"
              eager
              :src="dune.src"
            />
          </v-carousel>
        </v-card> -->

        <section id="details" class="mx-auto pt-5 pb-0 mb-2">
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",

  data: () => ({
    items: [
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (1).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (2).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (3).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (4).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (5).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (6).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (7).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (8).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (9).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (10).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (11).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (14).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (12).webp")
      },
      {
        src: require("@/assets/Main/landscaping/gold-coast/gold (13).webp")
      }
    ],
    pebbles: [
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (1).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (2).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (3).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (4).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (5).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (6).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (7).webp")
      },
      {
        src: require("@/assets/Main/landscaping/pebble-beach/pebble-beach (8).webp")
      }
    ],
    dunes: [
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (1).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (2).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (3).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (4).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (5).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (6).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (7).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (8).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (9).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (10).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (11).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (12).webp")
      },
      {
        src: require("@/assets/Main/landscaping/ocean-dune/ocean-dune (13).webp")
      }
    ],
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      [
        "https://www.facebook.com/blockhousekitchen.constantia/",
        "mdi-facebook",
        "Facebook"
      ],
      [
        "https://www.instagram.com/blockhousekitchen/",
        "mdi-instagram",
        "Instagram"
      ],
      ["https://blockhousekitchen.co.za/", "mdi-web", "Website"]
    ]
  })
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
</style>
